$color-grey: #f6f7f9;
$color-grey-hover: #edeff3;
$color-greyish: #e5e5e5;

$color-primary: #007bff;
$color-primary-border: #248eff;
$color-primary-active: #1486ff;
$color-primary-focus: #2990ff;
$color-primary-light: #52a5ff;

$color-yellow: #ffee32;
$color-red: #ff0000;

//random-colors
$color-random-logo-1: #00d3ff;
$color-random-logo-2: #b700ff;
$color-random-logo-3: #00ffa8;

$color-blueblack: #002349;
$color-shadow: #42526e;

$modal-header-border-width: 2px;
$modal-header-border-color: $color-grey;

//color-logo

$colors: $color-random-logo-1, $color-random-logo-2, $color-random-logo-3,
    $primary;
$key: random(length($colors));
$nth: nth($colors, $key);

$color-logo: $nth !default;

:export {
    color1: #00d3ff;
    // color-random-logo-1: #00D3FF;
    // color-random-logo-2: #B700FF;
    // color-random-logo-3: #00FFA8;
}

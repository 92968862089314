.input-search-container {
    width: auto;
}

.input-search {
    position: relative;
    padding-left: 16px !important;
    padding-right: 16px;
    border: 1.5px solid $gray-200 !important;
    border-radius: 8px;
    word-wrap: break-word;
    background-color: $color-grey;
    width: 100%;
}

.input-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.54;
    z-index: 1;
}

.has-icon {
    padding-right: 35px;
}

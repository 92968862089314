$font-primary: 'Gilroy';

$dropdown-min-width: 10rem !default;

$input-padding-y: 0.5rem !default;
$input-padding-x: 0.8rem !default;
$input-border-width: 2px;
$input-border-color: #edeff3;
$input-border-radius: 8px;
$input-focus-border-color: none;

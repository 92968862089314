.__json-pretty__ {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem !important;
    font-family: 'SFMono-Medium', 'SF Mono', 'Segoe UI Mono', 'Roboto Mono',
        'Ubuntu Mono', Menlo, Consolas, Courier, monospace;
    background: #f4f5f7 !important;
    border-radius: 8px;
    padding: 32px 24px;
    color: #d4d4d4 !important;

    .__json-key__ {
        color: #6554c0 !important;
    }

    .__json-value__ {
        color: #0052cc !important;
    }

    .__json-string__ {
        color: #006644 !important;
        word-break: break-word;
        white-space: break-spaces;
    }

    .__json-boolean__ {
        color: #0052cc !important;
    }
}

.json-copy {
    width: 16px;
    opacity: 0.7;
    color: #b0b0b0;
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
}

.json-copy-message {
    display: block;
    position: absolute;
    top: 24px;
    right: 24px;

    font-size: 13px;
    color: #b0b0b0;
}

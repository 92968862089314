.btn-main {
    padding: 10.5px 16px !important;
    background-color: $primary !important;
    color: $white;
    border-radius: 8px;
    outline: none !important;
    @extend %text-btn-main;
    transition: all 0.2s ease;

    &:hover {
        background-color: $color-primary-active !important;
    }

    &:active {
        background-color: $color-primary-light !important;
    }

    &:focus {
        background-color: $color-primary-focus !important;
    }
}

.btn-right {
    float: right !important;
}

.btn-dropdown {
    transition: background-color 0.2s ease;
    @extend %text-link;
    font-weight: 400;
    transition: all 0.2s ease;

    &:hover,
    &:focus {
        background-color: $color-grey !important;
        outline: none;
    }

    &:active {
        color: $black;
        background-color: $color-grey-hover !important;
    }
}

.custom-dropdown-btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 10px 32px 10px 8px;
    margin-right: 24px;
    color: rgba($black, 0.7);
    border-radius: 5px;
    background-color: $white;
    transition: all 0.2s ease;

    &:hover {
        color: rgba($black, 0.7);
        background-color: $color-grey;
    }

    &:active {
        color: rgba($black, 0.7);
        background-color: $color-grey-hover;
    }

    &:focus {
        outline: none;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        height: 2px;
        width: 100%;
        background-color: $primary;
        margin-top: 10px;
        border-color: $primary;
        border-radius: 4px 4px 0px 0px;
        transform: scale(0);
        transition: transform 0.2s ease;
    }

    &[aria-expanded='true'] &-arrow {
        transform: rotate(180deg);
    }

    &-active {
        font-weight: 500;
        color: $primary;
        background-color: rgba($primary, 0.08);
        transition: transform 0.2s ease;

        &::before {
            transform: scale(1);
        }
    }

    &-arrow {
        position: absolute;
        right: 10px;
        z-index: 1;
        transition: transform 0.2s ease;
    }

    &.summary-dropdown-btn {
        color: rgba($black, 0.7);
        background-color: $color-grey;

        &:hover {
            color: rgba($black, 0.7);
            background-color: $color-grey-hover;
        }
    }
}

.btn-link-main {
    background-color: transparent !important;
    color: $primary;
    transition: all 0.2s ease;

    &:hover {
        text-decoration: underline;
        color: $color-primary-border;
    }

    &:active {
        text-decoration: underline;
        color: $primary;
    }

    &:focus {
        outline: none;
        text-decoration: underline;
        color: $color-primary-border;
    }
}

.btn-icon-main {
    height: 32px;
    width: 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: transparent;
    transition: all 0.2s ease;

    &:focus {
        outline: none !important;
    }

    &:hover,
    &:focus {
        background-color: $color-grey-hover;
    }

    &:hover .btn-icon-main__icon {
        color: $black;
    }

    &:active {
        background-color: rgba($color-primary-border, 0.15);
    }

    &__icon {
        color: rgba($black, 0.7);
    }
}

.btn-round-icon-main {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: all 0.2s ease-in;

    &:focus {
        outline: none !important;
    }

    &:hover,
    &:focus {
        background-color: $color-grey-hover;
    }

    &:hover .btn-round-icon-main__icon {
        color: $black;
    }

    &:active {
        background-color: rgba($color-primary-border, 0.15);
    }

    &__icon {
        color: rgba($black, 0.7);
    }
}

.btn-spinner-ml {
    margin-left: 8px;
}

.btn-spase {
    width: 16px;
    height: 1px;
}

.btn-description {
    padding: 5px;
}

.btn-action {
    min-width: 32px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-grey-hover;
    border-radius: 4px;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    transition: all 0.2s ease;
    &.hover-default:hover {
        background-color: rgba(13, 110, 253, 0.08) !important;
    }
    &.hover-remove:hover {
        background-color: #fedbe2 !important;
    }
    &--active {
        background-color: rgba(13, 110, 253, 0.08) !important;
    }
}

.page-description-paragraph {
    p {
        display: block;
        margin: 1em 0;
    }
    span {
        line-height: 105%;
        word-break: break-all;
    }
}

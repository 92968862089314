.form-background {
    background-image: url(../../assets/img/tic-tac-toe.png);
    background-repeat: repeat;
}

.login-form {
    width: 600px;
    padding: 64px;

    background-color: #fff;
    border-radius: 8px;
}

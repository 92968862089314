.no-access-img {
    background-image: url(../../assets/img/seeker.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    height: 200px;

    @media (min-width: 480px) {
        height: 320px;
    }
    @media (min-width: 768px) {
        height: 468px;
    }
}

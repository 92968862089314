.left-sidebar {
    position: fixed;
    top: calc(var(--bannerHeight, 0px) + var(--topNavigationHeight, 0px));
    left: var(--leftPanelWidth, 0px);
    bottom: 0;
    width: var(--leftSidebarWidth, 0px);
    //transition: width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
    background-color: $gray-100;
}

.sidebar-container {
    position: relative;
    width: var(--leftSidebarWidth, 0px);
    //transition: width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
    z-index: 1;
    margin-left: 0px;
    .nav-link:focus {
        color: inherit;
    }
}

.sidebar {
    visibility: visible;
    transition: none 0s ease 0s;
    opacity: 1;
    overflow: hidden auto;
    height: 100%;
}

.sidebar-content {
    height: 100%;
    min-width: 240px;
}

.sidebar-dragger {
    width: 5px;
    height: 100%;
    min-height: calc(100vh - 66px);
    cursor: ew-resize;
    padding: 4px 0 0;
    border-top: 1px solid #f8f9fa;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #f4f7f9;
    transition: transform 0.3s ease;
}

.sidebar-header {
    padding: 16px 16px 24px;
}

.sidebar-link {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 8px 8px 11px;
    min-height: 48px;
    border-radius: $border-radius;
    cursor: pointer;
    @extend %text-link-normal;
    color: $black;
    &-route {
        padding: 4px 4px 4px 11px;
        border-radius: $border-radius * 2;
        min-height: 40px;
    }
    .btn-action.remove,
    .btn-action.edit {
        display: none;
        min-width: 24px;
        width: 24px;
        height: 24px;
    }

    &:hover {
        color: $black;
        background-color: $gray-200;
        .btn-action {
            background-color: $color-grey;
            &.remove,
            &.edit {
                display: flex;
            }
        }
    }
}

.sidebar-link-icon {
    margin-right: 16px;
    color: rgba($black, 0.7);
}

.sidebar-link-active {
    background-color: $gray-200;
    .btn-action {
        background-color: $color-grey;
    }
}

.nav-children {
    margin-left: 16px;
}

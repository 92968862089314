*,
*::before,
*::after {
    box-sizing: border-box;
}

body,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
input {
    margin: 0;
    padding: 0;
    border: none;
}

ul,
ol {
    list-style: none;
}

a,
input,
textarea,
button {
    outline: none;
}

a,
a:visited {
    text-decoration: none;
}

img {
    max-width: 100%;
    display: block;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

thead,
tbody,
tfoot,
tr,
td,
th {
    border-style: none !important;
    border-bottom-width: 0 !important;
}
